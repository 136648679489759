html, body {
    width: 100%;
    height: 100%;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    color: rgba(0,0,0,.87);
  }
  
  #root {
    height: 100%;
    width: 100%;
  }
  
  .full-width-height {
    height: 100vh;
    width: 100%;
  }
  
  .container {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    padding: 16px;
  }
  
  .no-margin {
    margin: 0;
  }
  
  .display-flex {
    display: flex;
  }
  
  .fill {
    flex: 1;
  }
  
  .center {
    text-align: center;
  }
  
  .mt16 {
    margin-top: 16px;
  }
  