* {
  font-family: 'Poppins', sans-serif !important;
}


#outlined-select-currency{
  color:white !important
}





.filter-text {
  color: #fff !important
}




@import "rsuite/dist/rsuite.css";

#root{ padding: 10px;border-radius: 20px; }

/* .apexcharts-tooltip span {
  color: #ffffff;
} */

/* .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background: white !important;
} */